import React from 'react';

const CallStonji: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4194_3913)">
        <path
          d="M6.7365 14.0127V12.2247C6.7365 7.37053 10.7224 3.41937 15.6184 3.41937H17.4137C22.3119 3.41937 26.2956 7.37053 26.2956 12.2247V14.0127H28.1316V12.2247C28.1316 6.36511 23.3237 1.59961 17.4137 1.59961H15.6184C9.70842 1.59961 4.90051 6.36511 4.90051 12.2247V14.0127H6.7365Z"
          fill="#1D1D1B"
        />
        <path
          d="M27.9988 10.6533L26.8728 10.568C24.4899 10.3875 22.4129 12.187 22.2337 14.5873L21.9502 18.3844C21.7711 20.7847 23.5576 22.8768 25.9405 23.0572L27.0665 23.1425C29.4494 23.323 31.5264 21.5235 31.7056 19.1232L31.9891 15.3261C32.1682 12.9258 30.3817 10.8337 27.9988 10.6533Z"
          fill="#1D1D1B"
        />
        <path
          d="M4.9441 23.0793L6.00927 22.9987C8.39221 22.8182 10.1787 20.7261 9.99955 18.3258L9.70764 14.4153C9.52846 12.015 7.45145 10.2155 5.0685 10.396L4.00334 10.4767C1.62039 10.6571 -0.166122 12.7492 0.0130522 15.1495L0.304967 19.0601C0.484141 21.4603 2.56115 23.2598 4.9441 23.0793Z"
          fill="#1D1D1B"
        />
        <path
          d="M25.7107 21.5286C25.2951 25.4388 22.0477 28.4118 18.1906 28.5073L18.2425 29.6424C18.2967 29.6424 18.3531 29.6424 18.4073 29.6401C22.7997 29.44 26.4604 25.9756 26.8511 21.4785L25.7129 21.5308L25.7107 21.5286Z"
          fill="#1D1D1B"
        />
        <path
          d="M5.97314 14.0127V12.2247C5.97314 7.37053 9.89353 3.41937 14.715 3.41937H16.481C21.3002 3.41937 25.2228 7.37053 25.2228 12.2247V14.0127H27.0295V12.2247C27.0295 6.36511 22.2983 1.59961 16.481 1.59961H14.715C8.89763 1.59961 4.1665 6.36511 4.1665 12.2247V14.0127H5.97314Z"
          fill="#008E5A"
        />
        <path
          d="M25.2657 21.248C24.6763 25.1355 21.2956 27.9584 17.4385 27.8765V29.0116C17.4927 29.0116 17.5491 29.0162 17.6033 29.0162C22.0002 29.0162 25.8122 25.7224 26.4039 21.248H25.2635H25.2657Z"
          fill="#008E5A"
        />
        <path
          d="M17.8952 27.6403L16.7782 27.7248C16.0892 27.777 15.5726 28.382 15.6244 29.076L15.6361 29.2325C15.6879 29.9265 16.2885 30.4468 16.9775 30.3946L18.0945 30.31C18.7835 30.2579 19.3 29.6529 19.2482 28.9589L19.2365 28.8024C19.1847 28.1084 18.5842 27.5881 17.8952 27.6403Z"
          fill="#1D1D1B"
        />
        <path
          d="M17.721 27.1754L16.604 27.26C15.915 27.3122 15.3984 27.9171 15.4503 28.6111L15.4619 28.7676C15.5137 29.4617 16.1143 29.982 16.8033 29.9298L17.9203 29.8452C18.6093 29.793 19.1258 29.1881 19.074 28.4941L19.0624 28.3376C19.0105 27.6435 18.41 27.1232 17.721 27.1754Z"
          fill="#00D483"
        />
        <path
          d="M24.7012 9.22936C24.7012 9.22936 24.9812 9.97318 25.0986 10.742C25.0986 10.742 26.0471 10.4077 26.9685 10.5077C26.9685 10.5077 26.8149 9.80031 26.4062 8.58789L24.7034 9.22936H24.7012Z"
          fill="#1D1D1B"
        />
        <path
          d="M24.7012 9.18157C24.69 9.18157 24.6787 9.17475 24.6741 9.16337C24.6696 9.14973 24.6741 9.1338 24.69 9.12698L25.862 8.64702L24.708 8.97685C24.6945 8.9814 24.6787 8.9723 24.6741 8.95865C24.6696 8.945 24.6764 8.92908 24.69 8.92226L26.1104 8.36495C26.124 8.3604 26.142 8.36495 26.1466 8.38088C26.1511 8.39452 26.1466 8.41272 26.1307 8.41727L25.2681 8.7562L26.343 8.44912C26.3566 8.44684 26.3724 8.45367 26.3769 8.46732C26.3814 8.48096 26.3769 8.49689 26.3611 8.50371L25.4826 8.86311L26.395 8.56058C26.4108 8.55603 26.4266 8.56285 26.4311 8.57878C26.4356 8.5947 26.4288 8.61062 26.413 8.61517L24.7103 9.1793C24.7103 9.1793 24.7035 9.1793 24.7012 9.1793V9.18157Z"
          fill="#1D1D1B"
        />
        <path
          d="M24.8367 22.8724C24.8367 22.8724 25.3426 23.068 25.9952 23.1067L25.6926 23.9461L24.5499 23.5252L24.8345 22.8701L24.8367 22.8724Z"
          fill="#1D1D1B"
        />
        <path
          d="M4.30652 10.4873C4.30652 10.4873 5.32727 10.5851 6.07702 10.8626L6.41351 9.46136L4.67688 8.9541C4.67688 8.9541 4.39459 9.81849 4.30652 10.4873Z"
          fill="#1D1D1B"
        />
        <path
          d="M19.2452 28.8939C19.2452 28.8939 19.2361 28.257 18.8816 27.7725C18.8816 27.7725 19.051 28.0181 19.0781 28.5368C19.1052 29.0554 19.2452 28.8939 19.2452 28.8939Z"
          fill="#1D1D1B"
        />
        <path
          d="M19.2519 29.0253C19.2519 29.0253 19.2519 29.0253 19.2496 29.0253C19.2338 29.0253 19.2225 29.0094 19.2248 28.9934C19.2248 28.9889 19.2768 28.5157 18.9967 27.897C18.9899 27.8834 18.9967 27.8675 19.008 27.8606C19.0193 27.8538 19.0374 27.8584 19.0464 27.8697C19.0554 27.8811 19.2451 28.17 19.3851 28.7227C19.3897 28.7387 19.3806 28.7523 19.3648 28.7569C19.349 28.7614 19.3332 28.7523 19.3309 28.7364C19.2971 28.6045 19.2609 28.4862 19.2248 28.3838C19.3106 28.7478 19.2858 28.9866 19.2835 29.0003C19.2835 29.0139 19.27 29.0253 19.2564 29.0253H19.2519Z"
          fill="#1D1D1B"
        />
        <path
          d="M6.59869 22.9111C6.59869 22.9111 5.89184 23.0521 4.94336 23.1044C3.99488 23.1568 4.80335 21.7646 4.80335 21.7646L6.59869 22.9134V22.9111Z"
          fill="#1D1D1B"
        />
        <path
          d="M27.7408 23.1406L26.0426 23.2316L26.1262 23.1133C26.1262 23.1133 26.2955 23.1111 26.5552 23.1042C26.8149 23.0974 27.7408 23.1406 27.7408 23.1406Z"
          fill="#1D1D1B"
        />
        <path
          d="M29.2246 10.9328C29.2246 10.9328 28.3032 10.5529 26.7269 10.4893C26.7269 10.4893 27.8764 10.4915 28.8249 10.9328C29.7734 11.3741 29.2246 10.9328 29.2246 10.9328Z"
          fill="#1D1D1B"
        />
        <path
          d="M0.305979 19.0817C0.485154 21.482 2.56166 23.2815 4.94399 23.1011C7.32631 22.9207 9.11232 20.8286 8.93314 18.4284L8.64106 14.5155C8.46189 12.1153 6.38538 10.3157 4.00305 10.4962C1.62073 10.6766 -0.165279 12.7687 0.0138953 15.1689L0.305979 19.0817Z"
          fill="#00D483"
        />
        <path
          d="M30.8646 15.1723C31.0438 12.772 29.2578 10.68 26.8755 10.4995C24.4931 10.3191 22.4166 12.1186 22.2375 14.5189L21.9454 18.4317C21.7662 20.832 23.5522 22.9241 25.9345 23.1045C28.3169 23.2849 30.3934 21.4854 30.5725 19.0851L30.8646 15.1723Z"
          fill="#00D483"
        />
      </g>
      <defs>
        <clipPath id="clip0_4194_3913">
          <rect
            width="32"
            height="28.8"
            fill="white"
            transform="translate(0 1.59961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallStonji;
